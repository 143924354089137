
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
moment.locale("id");

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: null,
          deleteTime: null,
          name: "",
          initial: "",
          status: "",
          npwpImageUrl: "",
          npwpNumber: "",
          npwpStatus: true,
          salesAreaName: "",
          note: "",
          _id: "",
        },
      ],
      listSekolah: [],
      sortkonfirmasi: true,
      listConfirmation: [
        {
          label: "Sudah",
          value: true,
        },
        {
          label: "Belum",
          value: false,
        },
      ],
      listStatus: [
        {
          label: "Disetujui",
          value: "approve",
        },
        {
          label: "Ditolak",
          value: "reject",
        },
        {
          label: "Menunggu Konfirmasi",
          value: "",
        },
      ],
      listType: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      items: {
        errornote: "",
        errorconfirmation: "",
      },

      name: "",
      schoolId: "",
      schoolName: "",
      confirmation: "",
      image: null,
      roleuser: "",
      filterConfir: "&sort=updateTime&dir=-1",
      itemId: "",
      npwpImage: "",
      setatus: "",
      updateFoto: false,
      foto: "",
      npwp: {
        number: "",
        imageUrl: "",
      },
      formDetail: {
        name: "",
        phone: "",
        email: "",
        initial: "",
        fundingName: "",
        picName: "",
        picPhone: "",
        picEmail: "",
        schoolType: "",
        picProduct: "",
        status: "",
        targetLive: "",
        customApp: "",
        address: {
          province: "",
          city: "",
          regency: "",
          detail: "",
        },
        bankData: [],
        implementatorData: "",
        gps: "",
        npwp: {
          number: "",
          imageUrl: "",
        },
        referenceFrom: "",
        note: "",
        salesArea: "",
        automaticPayment: "",
        blockCardTransaction: "",
        faceRecognition: "",
        _id: "",
      },

      
      showFilter : false,
      timer: 0,
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);

    this.roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    this.schoolName = JSON.parse(localStorage.getItem("schoolName")!);
    
    this.getData(this.paging.size, this.paging.page, this.filterConfir);
    this.getListSekolah();
    setCurrentPageBreadcrumbs("Data NPWP", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    sorting(data) {
      // var sort = data ? "-1" : "1"
      console.log('data->',data)
      var newsorting = "npwpStatus="+data+"&sort=updateTime&dir=-1";
      // if (data == "Sudah") {
      //   newsorting = "npwpStatus=true&sort=updateTime&dir=-1";
      // }
      // if (data == "Belum") {
      //   newsorting = "npwpStatus=false&sort=updateTime&dir=-1";
      // }
      this.filterConfir = newsorting;

      this.getData(this.paging.size, this.paging.page, newsorting);
    },
    getData(size, page, request) {
      this.isLoading = true;
      let konfirmasi = "";
      let search1 = "";
      if (this.schoolName) {
        search1 = "name=" + this.schoolName + "&";
      } else {
        search1;
      }

      this.paging.page = page;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/npwp?" +
          search1 +
          request +
          "page=" +
          page +
          "&size=" +
          size +
          `&${request}`
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.listType = data.content;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },

    reset() {
      this.npwpImage = "";
      this.setatus = "";
      this.updateFoto = false;
      this.foto = "";
      (this.npwp = {
        number: "",
        imageUrl: "",
      }),
        (this.formDetail = {
          name: "",
          phone: "",
          email: "",
          initial: "",
          fundingName: "",
          picName: "",
          picPhone: "",
          picEmail: "",
          schoolType: "",
          picProduct: "",
          status: "",
          targetLive: "",
          customApp: "",
          address: {
            province: "",
            city: "",
            regency: "",
            detail: "",
          },
          bankData: [],
          implementatorData: "",
          gps: "",
          npwp: {
            number: "",
            imageUrl: "",
          },
          referenceFrom: "",
          note: "",
          salesArea: "",
          automaticPayment: "",
          blockCardTransaction: "",
          faceRecognition: "",
          _id: "",
        });
    },
    pilihSekolah(event) {
      var namaSekolah = "";
      if (event) {
        // this.schoolName = event;
        this.schoolId = event;
        let school: any = this.listSekolah.find(
          (item: any) => item._id == this.schoolId
        );
        namaSekolah = school.name;
        this.schoolName = school.name;
      } else {
        this.schoolName = "";
      }
      localStorage.setItem("schoolName", JSON.stringify(namaSekolah));
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page, this.filterConfir);
    },
    handleFileUpload(event, id) {
      // this.saveNpwp(event.target.files[0], id)
      this.updateImage(event);
    },

    getImage(item, id) {
      ApiService.getWithoutSlug(
        `crmv2/main_school/school/school/download_npwp_by_file?schoolId=${id}&imgFile=${item}`
      ).then((resp) => {
        this.foto = "" + resp.config.baseURL + resp.config.url;
      });
    },

    updateImage(event) {
      this.updateFoto = true;
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.foto = "" + e.target?.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },

    saveNpwp(data, e) {
      let formData = new FormData();
      formData.append("npwpFile", data);

      if (this.updateFoto == true) {
        ApiService.postWithData(
          "/crmv2/main_school/school/school/upload_npwp?schoolId=" + e,
          formData
        )
          .then((res) => {
            this.npwpImage = res.data.npwp.imageUrl;
            // router.push({ path: "/superadmin/sekolah" });
            // this.getImage(res.data.npwp.imageUrl, e)
            this.submitAdd(res.data.npwp.imageUrl);
            // this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
          });
      } else {
        this.submitAdd(this.formDetail.npwp.imageUrl);
      }
    },
    submitAdd(e) {
      const toast = useToast();

      var data = {
        name: this.formDetail.name,
        phone: this.formDetail.phone,
        email: this.formDetail.email,
        initial: this.formDetail.initial,
        fundingName: this.formDetail.fundingName,
        picName: this.formDetail.picName,
        picPhone: this.formDetail.picPhone,
        picEmail: this.formDetail.picEmail,
        schoolType: this.formDetail.schoolType,
        picProduct: this.formDetail.picProduct,
        status: this.formDetail.status,
        targetLive: this.formDetail.targetLive,
        customApp: this.formDetail.customApp,
        address: {
          province: this.formDetail.address.province,
          city: this.formDetail.address.city,
          regency: this.formDetail.address.regency,
          detail: this.formDetail.address.detail,
        },
        bankData: this.formDetail.bankData,
        implementatorData: this.formDetail.implementatorData,
        gps: this.formDetail.gps,
        npwp: {
          number: this.npwp.number,
          imageUrl: e,
        },
        referenceFrom: this.formDetail.referenceFrom,
        note: this.formDetail.note,
        salesArea: this.formDetail.salesArea,
        automaticPayment: this.formDetail.automaticPayment,
        blockCardTransaction: this.formDetail.blockCardTransaction,
        faceRecognition: this.formDetail.faceRecognition,
        _id: this.formDetail._id,
      };

      var c = JSON.stringify(data);
      var d = JSON.parse(c);

      ApiService.putWithData(
        "crmv2/main_school/school/school/" + this.formDetail._id,
        d
      )
        .then((res) => {
          toast.success("Berhasil Update NPWP");
          this.isLoading = false;
          location.reload();
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
          this.isLoading = false;
        });
    },

    dateTime2(value) {
      return moment(value).format("DD MMMM YYYY");
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    getId(item, status) {
      this.itemId = item;
      this.setatus = status == true ? "Sudah" : "Belum";

      this.isLoading = true;
      const toast = useToast();

      ApiService.getWithoutSlug("crmv2/main_school/school/school/" + item)
        .then((res) => {
          var tes = moment(res.data.requested_time).format("DD MMMM YYYY");
          this.formDetail = res.data;
          this.npwp = res.data.npwp;
          this.npwpImage = res.data.npwp.imageUrl;
          this.getImage(res.data.npwp.imageUrl, res.data._id);
          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },
    handleBlobResponse(blobFileData, extension, nama) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data_Npwp_" + nama + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    getDownloadById(id, name, title) {
      const toast = useToast();
      var b = JSON.parse(localStorage.getItem("sekolahId")!);
      this.isLoading = true;
      ApiService.getDownload(
        `crmv2/main_school/school/school/download_npwp_by_file?schoolId=${id}&imgFile=${name}`
      )
        .then((response) => {
          this.handleBlobResponse(response.data, "jpg", title);
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          toast.error("Gagal Unduh File");
          this.isLoading = false;
        });
    },
    getDownloadAll() {
      const toast = useToast();
      var b = JSON.parse(localStorage.getItem("sekolahId")!);
      this.isLoading = true;
      ApiService.getDownload(
        `crmv2/main_school/school/school/npwp/download/school_npwp.xlsx`
      )
        .then((response) => {
          this.handleBlobResponse(response.data, "xlsx", "Semua");
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          toast.error("Gagal Unduh File");
          this.isLoading = false;
        });
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page, this.filterConfir);
    },
    pilihStatus(event) {
      if (event) {
        this.confirmation = event;
      } else {
        this.confirmation = "";
      }
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page, this.filterConfir);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page, this.filterConfir);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page, this.filterConfir);
    },

    getExtensionFile(val) {
      if (val) {
        const myArray = val.split(".");
        return myArray[1];
      } else {
        return "-";
      }
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
          const page = this.paging.page;
          // this.getData(this.paging.size, page);
        }, 1000)
    },
    showHideFilter(){
      this.showFilter = !this.showFilter;
    },
  },
});
